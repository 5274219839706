/**
* Ajax utils, store there all little utilities to handle ajax request
*
* @use import ajax.js
*/
import CryptoJS                                             from 'crypto-js';
import _                                                    from 'lodash';
import * as Sentry                                          from '@sentry/react';
import {
    navigateToErrorPage, startLoginProcess,
    getAuthorizationHeaders
}                                                           from 'store/actions/auth';
import { makeCancelable }                                   from './promise';

/**
* Regroup ajax helpers in a single object
*
* @return {object}
*/
export const helpers = {

    /**
    * Re-Trigger all requests that has been returned in 206 or 409 loading/incomplete status
    *
    * @return void
    */
    triggerLoading() {
        if (!loadingPool.length && !tooEarlyPool.length) {
            return;
        }

        helpers.reDoLoadingPool();

        helpers.reDoTooEarlyPool();

        // Then trigger for the next case
        helpers.triggerLoading();
    },

    /**
    * Re-Trigger all requests that has been returned in 206 loading/incomplete status
    *
    * @return void
    */
    reDoLoadingPool() {
        if (!loadingPool.length) {
            return;
        }

        const {
            uri, data, options, cb, id
        } = loadingPool.shift();

        // RE-do
        helpers.ajax(uri, data, options, id).then(cb);
    },

    /**
    * Re-Trigger all requests that has been returned in 409 loading/incomplete status
    *
    * @return void
    */
    reDoTooEarlyPool() {
        if (!tooEarlyPool.length) {
            return;
        }
        const sortedPool = _.sortBy(tooEarlyPool, query => query.timestamp),
            queryToReDo = sortedPool.shift(),
            {
                uri, data, options, cb, id
            }             = queryToReDo;

        // Update the pool
        tooEarlyPool = sortedPool;

        // RE-do
        helpers.ajax(uri, data, options, id).then(cb);
    },


    /**
    * Return the currrent API url
    *
    * @return string
    */
    getApiUrl: (path = '', api = 'api') =>  `/${api}${path}`, // eslint-disable-line  no-restricted-globals

    /**
    * The client token
    *
    * @return string
    */
    getToken: () => localStorage.getItem('OI_TOKEN'),

    /**
    * Return the uri path from a provided API full url
    *
    * @param {string} url The full url aka: https://api.domain.com/resource/id
    *
    * @return {string} The uri aka: /resource/id
    */
    extractUriFromHref: (url) => url.replace(helpers.getApiUrl(), ''),

    /**
    * Extract some header to keep them as local value
    *
    * @return boolean
    */
    storeLocallyResponsesHeaders: (response) => {
        response.headers.forEach((value, key) => {
            if (key !== 'x-cache') {
                return;
            }
            localStorage.setItem('CACHE_VERSION', value);
        });
    },

    /**
    * Create the header object with authorizaton for a specific request
    *
    * @param {string}  method The request method
    *
    * @return {object} The header object
    */
    getHeadersFromRequest: async (method, {data}) => {
        const isPost = method === 'POST',
            headers  = {},
            {
                noAuthHeaders,
                byPassWaitingAccessToken,
                sentrySpan
            } = data,
            auth      = noAuthHeaders
                ? {} // Get Auth is public
                : await getAuthorizationHeaders({byPassWaitingAccessToken}),
            workspace = window.getWorkspace ? await window.getWorkspace() : false;

        // Method is POST, specify the content type
        if (isPost) {
            headers['Content-Type'] = 'application/json';
        }

        // Manage injected workspace (Capture, etc..)
        if(workspace) {
            headers['x-workspace'] = workspace;
        }

        // Inject authorization headers
        Object.assign(
            headers,
            auth,
            {
                'x-referer': document.location,
            }
        );

        return makeSentryHeaders(headers, sentrySpan);
    },

    /**
    * Encrypt a string to a custom crypted base64 and return it
    *
    * @param  {string} stringToCrypt The string to encryp
    * @param  {string} key           The encryption key
    *
    * @return {string}
    */
    encrypt: (stringToCrypt, key) => btoa(CryptoJS.HmacSHA256(stringToCrypt, key).toString()),

    /**
    * Create the XHR promise Do the XHR
    *
    * @param {string} The targeted uri
    * @param {object} Some data to complete the enrich the AJAX call
    *
    * @return {Promise}
    */
    executeXHR: async (uri, data, api) => {
        const customHeaders = await helpers.getHeadersFromRequest(data.method, { uri, data });

        // Extend headers with the authorizaton
        Object.assign(data.headers, customHeaders);

        // Create the XHR promise
        return fetch(helpers.getApiUrl(uri, api), { ...data, credentials: 'include' });
    },

    /**
    * Encapsule the fetch polyfill into a normalized promise to control flow
    *
    * @param {string}  The targeted uri
    * @param {object}  Some data to complete the enrich the AJAX call
    * @param {boolean} Force the token refresh
    *
    * @returns {Promise}
    *
    * @todo: eslint compatibility.
    */
    /* eslint-disable  max-lines-per-function */
    ajax: (uri, data = {}, options = {}, id = null) => {    /* eslint-disable-line  max-params */
        const requestID = id || _.uniqueId('ajax'),
            controller  = new AbortController(),
            { signal }  = controller,
            {
                api, byPassWaitingAccessToken, noAuthHeaders, sentrySpan
            }           = options,
            promiseXHR  = helpers.executeXHR(
                uri,
                {
                    ...data,
                    signal,
                    byPassWaitingAccessToken,
                    noAuthHeaders,
                    sentrySpan,
                },
                api
            );

        let rawResponse;

        return makeCancelable(
            new Promise((resolve, reject) => {
                promiseXHR                                                // Here, exec the XHR
                    .then((response) => {
                        const contentType = response.headers.get('Content-Type'),
                            isJson        = contentType === 'application/json';

                        if (response.ok && response.status === 200 && !isJson) {
                            rawResponse = response;                                             // Keep for further use
                            return response.blob();
                        }
                        helpers.storeLocallyResponsesHeaders(response);                         // Keep X-Cache etc..
                        rawResponse = response;                                                 // Keep for further use
                        return response.ok && response.status !== 204 ? response.json() : null; // Obtain the body JSON
                    }).then((body) => {
                        const canceledRequestIndex = canceledRequestsIds.indexOf(requestID);
                        // Request has been cancel, nothing to do
                        if (canceledRequestIndex !== -1) {
                            canceledRequestsIds.splice(canceledRequestIndex, 1);
                            return;
                        }

                        // Create the response object from response and its body
                        const responseObj = helpers.prepareResponse({ response: rawResponse, body }),
                            { status }    = responseObj,
                            /**
                            * Reject the call
                            */
                            rejectFn      = () => {
                                const errors      = responseObj.errors.errors || responseObj.errors,
                                    stripedErrors = _.mapValues(errors, (error) => (
                                        _.isString(error)
                                            ? error.replace(/(<([^>]+)>)/ig, '')
                                            : error
                                    ));

                                // Add additional informations
                                stripedErrors.label   = 'Ajax error';
                                stripedErrors.data    = data;
                                stripedErrors.uri     = uri;
                                stripedErrors.api     = api;
                                stripedErrors.status  = status;
                                stripedErrors.response = responseObj;

                                reject(stripedErrors);
                            };

                        if (status.code === 206 || status.code === 409) {
                            const pool = status.code === 206 ? loadingPool : tooEarlyPool;
                            // Otherwise add the request to the pool
                            pool.push({
                                id       : requestID,
                                timestamp: Date.now(),
                                cb       : resolve,
                                uri,
                                data,
                                options  : {
                                    ...options,
                                },
                            });
                            return;
                        }

                        // It's ok, finalize the call.
                        if (status.code < 400) {
                            // Finalize the call for lt 400 status code
                            responseObj.errors = null;

                            requestAnimationFrame(() => resolve(responseObj));
                            return;
                        }

                        // Cache is outdated, remove persistance, then reload.
                        if (status.code === 409) {
                            localStorage.removeItem('persist:knowledge');
                            document.location.reload();
                        }

                        // Must be re-authentified
                        if (status.code === 401) {
                            startLoginProcess();
                        }

                        // Other cases are terminating the app.
                        if ([503, 402].indexOf(status.code) !== -1) {
                            navigateToErrorPage(status.code);
                        }

                        return rejectFn();
                    },
                    // ERROR ?
                    (err) => {
                        helpers.manageAjaxError(err, uri, data, { reject: () => {}, resolve: () => {} });
                    });
            }).catch((error) => error),                 // Keep trace
            () => {
                helpers.cancelLoadingState(requestID);
                controller.abort();
            }
        );
    },

    /**
    * Cancel the reloading/206 for a specific id
    *
    * @return void
    */
    cancelLoadingState(requestID) {
        canceledRequestsIds.push(requestID);
    },

    /**
    * Handle error from ajax
    *
    * @param err    The triggered error
    * @param string uri The previous URI
    * @param data   data request data
    * @param func   rejectFn The reject function    request data
    *
    * @return void
    */
    manageAjaxError(err, uri, data) {
        if (err && err.code && err.code !== DOMException.ABORT_ERR) {
            console.log('ERROR', err.message, uri, JSON.stringify(data));
            throw new Error(err);
        }
    },

    /**
    * Prepare a response that will be returned to
    *
    * @param {string} The targeted uri
    * @param {object} Some data to complete the enrich the AJAX call
    *
    * @returns {Promise}
    */
    prepareResponse: (data) => {
        const { response, body } = data;

        return {
            body,
            status: {
                code: response.status,
                text: response.statusText
            },
            headers: response.headers,
            errors : body || {}
        };
    },
};

/**
 * Transform object in HTTP querystring like ?name=ferret&color=purple
 * @param {object} data
 * @returns {string}
 */
const makeURIParams = (data) => {
    return !_.isNull(data)
        ? Object.keys(data)
            .map((prop) => {
                const encodedProp = encodeURIComponent(prop);

                if (_.isObjectLike(data[prop])) {
                    return Object.keys(data[prop])
                        .map((subprop) => {
                            const encodedSubProp = encodeURIComponent(subprop),
                                encodedValue = encodeURIComponent(data[prop][subprop]);
                            return `${encodedProp}[${encodedSubProp}]=${encodedValue}`;
                        })
                        .join('&');
                }

                return `${encodedProp}=${encodeURIComponent(data[prop])}`;
            })
            .join('&')
        : '';
};

/**
* Implement the API POST method
*
* @param {string} uri  The URI where the XHR will be posted
* @param {object} body Some parameters to send with the current post
* @param {object} data Additionnal data to perform the POST request
*
* @returns {Promise} The result of the POST
*/
export const post = (uri, body = {}, data = {}) => {
    // Create the object to be posted to the API
    const { apiService } = data,
        ajaxOption  = { api: apiService },
        dataToPost    = {
            method : 'POST',
            body   : JSON.stringify(body || {}),
            headers: {}
        };

    Object.assign(data, dataToPost);

    return helpers.ajax(uri, _.omit(data, ['apiService']), ajaxOption);
};

/**
* Implement the Data API POST method
*
* @param {string} uri  The URI where the XHR will be posted
* @param {object} body Some parameters to send with the current post
* @param {object} data Additionnal data to perform the POST request
*
* @returns {Promise} The result of the POST
*/
export const dataPost = (uri, body = {}, data = {}) => post(uri, body, _.merge(data, { apiService: 'data-api' }));

/**
* Implement the API PATCH method
*
* @param {string} uri  The URI where the XHR will be posted
* @param {object} body Some parameters to send with the current post
* @param {object} data Additionnal data to perform the PATCH request
*
* @returns {Promise} The result of the POST
*/
export const patch = (uri, body = {}, data = {}) => {
    // Create the object to be posted to the API
    const { apiService } = data,
        ajaxOption  = { api: apiService },
        dataToPost = {
            method : 'PATCH',
            body   : JSON.stringify(body || {}),
            headers: {}
        };

    Object.assign(data, dataToPost);

    return helpers.ajax(uri, _.omit(data, ['apiService']), ajaxOption);
};

/**
* Implement the Data API PAtch method
*
* @param {string} uri  The URI where the XHR will be posted
* @param {object} body Some parameters to send with the current post
* @param {object} data Additionnal data to perform the POST request
*
* @returns {Promise} The result of the POST
*/
export const dataPatch = (uri, body = {}, data = {}) => patch(uri, body, _.merge(data, { apiService: 'data-api' }));

/**
* Implement the API GET method
*
* @param {string} uri The URI where the XHR will be posted
*
* @returns {Promise} The result of the POST
*/
export const get = (uri, options = {}) => {
    if (uri === '' || !uri) {
        throw new Error('Uri cannot be null');
    }
    const sentrySpan = Sentry.getActiveSpan();

    // Create the parameters object
    const { data   = null, apiService, byPassWaitingAccessToken, noAuthHeaders } = options,
        ajaxOption = { api: apiService,  byPassWaitingAccessToken, noAuthHeaders, sentrySpan },
        dataToGet  = {
            method : 'GET',
            headers: {}
        },
        params = makeURIParams(data);

    return helpers.ajax(`${uri}?${params}`, dataToGet, ajaxOption);
};

/**
* Implement the API GET method
*
* @param {string} uri The URI where the XHR will be posted
*
* @returns {Promise} The result of the POST
*/
export const dataGet = (uri, options = {}) => get(uri, _.merge(options, { apiService: 'data-api' }));

/**
* Implement the API DELETE method
*
* @param {string} uri The URI where the XHR will be 'deleted'
*
* @returns {Promise} The result of the POST
*/
export const del = (uri, options = {}) => {
    // Create the parameters object
    const {
            apiService,
            body,
            data = null
        }            = options,
        ajaxOption   = { api: apiService },
        dataToDelete = {
            method : 'DELETE',
            body   : JSON.stringify(body || {}),
            headers: {}
        },
        params = makeURIParams(data);

    return helpers.ajax(`${uri}?${params}`, dataToDelete, ajaxOption);
};

/**
* Implement the API DELETE method
*
* @param {string} uri The URI where the XHR will be posted
*
* @returns {Promise} The result of the POST
*/
export const dataDel = (uri, options = {}) => del(uri, _.merge(options, { apiService: 'data-api' }));

// Initialize loading pool
const loadingPool       = [],
    canceledRequestsIds = [];
let tooEarlyPool        = []; // This pool is updated by sort
setInterval(helpers.triggerLoading, 1000);


/**
 * Creates a new headers object that includes Sentry trace and baggage headers.
 *
 * @param {Object} [baseHeaders={}] - The base headers object to start with.
 * @returns {Object} - A new headers object that includes the Sentry trace and baggage headers, if available.
 */
export const makeSentryHeaders = (baseHeaders = {}, span) => {
    const headers = {},
        activeSpan = span || Sentry.getActiveSpan(),
        rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : activeSpan,
        sentryTraceHeader = rootSpan
            ? Sentry.spanToTraceHeader(rootSpan)
            : undefined,
        sentryBaggageHeader = rootSpan
            ? Sentry.spanToBaggageHeader(rootSpan)
            : undefined;

    headers['sentry-trace'] = sentryTraceHeader;
    headers.baggage         = sentryBaggageHeader;

    return { ...baseHeaders, ...headers };
};


/**
 * Get promise to fetch entities
 *
 * @return Promise
*/
export const getEntitiesModelsPromise = async (modelIds, params = {}) => {
    // Remove duplicates
    const ids  = modelIds && _.uniq(modelIds),
        // Ask data api (50 orgunits fetch is fast)
        // Chunk it to not break HTTP querystring
        modelIdsChunk         = _.chunk(ids, 50),
        entitiesModelsPromise = modelIdsChunk.map(
            chunk => new Promise(resolve => {
                dataGet('/entities', { data: { ids: chunk, ...params } }).then(
                    (results) => {
                        const entities  = results.body,
                            originalIds = entities.map(model => model.entity.originalId)
                                .filter(id => id && id !=='');

                        if (!originalIds || originalIds.length === 0) {
                            resolve({entities});
                            return;
                        }

                        dataGet('/entities', { data: { ids: originalIds, ...params } }).then(
                            (results2) => {
                                const originalEntitiesModels = results2.body,
                                    entitiesIds              = originalEntitiesModels
                                        && originalEntitiesModels.map
                                        && originalEntitiesModels.map(model => model.id);

                                if (!entitiesIds) {
                                    resolve({entities});
                                    return;
                                }

                                dataGet('/query', { data: { entities: entitiesIds } }).then(
                                    (results3) => {
                                        resolve({ entities, queriesModels: results3.body });
                                    }
                                );
                            }
                        );
                    }
                );
            })
        ),
        results       = await Promise.all(entitiesModelsPromise),
        mergedResults = { entities: [], queriesModels: [] };

    // Merge chunked results
    results.forEach(result => {
        mergedResults.entities = mergedResults.entities
            .concat(result.entities)
            .filter(entity => !!entity);
        mergedResults.queriesModels = mergedResults.queriesModels
            .concat(result.queriesModels)
            .filter(queriesModel => !!queriesModel);
    });

    return mergedResults;
};

// Then export defaults
export default {
    helpers, post, get, del, dataPost, dataGet, getEntitiesModelsPromise, makeSentryHeaders,
};
