import _                      from 'lodash';
import React, { Component }   from 'react';
import { Modal }              from 'antd';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { learn }              from '../store/actions/knowledge';
import { getResourceFilters } from '../store/actions/resource';

import { makeStrClassName }   from 'utils/text';
import { Icon }               from 'helpers';

import FilterBlock            from './Filters/Block';

import './Filters/assets/filters.less';
import './Filters/assets/range.less';
import './Filters/assets/groupedlist.less';

/**
* Filters
*/
class Filters extends Component {

    /**
    * Constructor, setting up the state.
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onChange', 'setFilters', 'showFilterHelp', 'hideFilterHelp');

        this.state = {
            values              : {},
            dynamicFiltersLabels: {}
        };
    }

    /**
    * When component is ready.
    *
    * @return JSX
    */
    componentDidMount() {
        const { learnKnowledge, registerCallbacks } = this.props;

        // Learn all needed Knowledges, then fill the state with collections
        learnKnowledge('filters')
            .then(this.setState.bind(this));

        registerCallbacks && registerCallbacks('setFilters', this.setFilters);
    }


    /**
     * Set filters
     *
     * @param {Object} filters
     */
    setFilters(filters) {
        const { onChange } = this.props;

        // Keep new filters
        this.setState({ values: filters });

        // Update parent Component
        onChange(filters);
    }


    /**
    * Get state for props and previous states
    *
    * store :
    *          - dynamic Filters labels
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous states
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevStates) {
        const { dynamicFilters } = nextProps,
            states = {
                ...prevStates
            };

        // Keep selected labels
        _.each(dynamicFilters, (filters, filterKey) => {
            _.each(filters, (filter) => {
                _.set(
                    states.dynamicFiltersLabels,
                    `${filterKey}.${filter.id}`,
                    _.get(filter, 'label', filter.id)
                );
            });
        });

        return states;
    }


    /**
    * Update the current filter list
    *
    * @return void
    */
    onChange(filterKey, values) {
        const {
                onChange, dynamicFilters, resource
            }                       = this.props,
            {
                values: allFiltersValues
            }                       = this.state,
            // Keep selected values
            newAllFiltersValues     = {
                ...allFiltersValues,
                [filterKey]: values
            },
            resourceFilters         = getResourceFilters(newAllFiltersValues, resource),
            // Clean Filter (only resource filters)
            cleanedAllFiltersValues = resourceFilters.length > 0
                ? _.pick(newAllFiltersValues, resourceFilters)
                : newAllFiltersValues;


        // Keep last no-empty dynamicFilters
        if (!_.isArray(dynamicFilters) || dynamicFilters.length !== 0) {
            this.setState({ lastDynamicFilters: dynamicFilters });
        }

        // Set filters values
        this.setState({ values: cleanedAllFiltersValues });

        onChange(cleanedAllFiltersValues);
    }


    /**
     * Show filter help modal
     */
    showFilterHelp() {
        this.setState({ showFilterHelp: true });
    }

    /**
     * Hide filer help modal
     */
    hideFilterHelp() {
        this.setState({ showFilterHelp: false });
    }

    /**
     * Render multi-valued help button with tooltip
     *
     * @param {object} filterOptions
     * @returns
     */
    getHelpInfo(filterKey) {    // eslint-disable-line max-lines-per-function
        const { filters }    = this.state,
            filterDefinition = filters.find((filter) => filter.id === filterKey),
            { options, id }  = filterDefinition || {},
            { multiValued }  = options || {},
            content          = [
                (
                    <>
                        Which means a list item can have multiple values on this filter
                    </>
                ),
                id === 'tag'&& (
                    <>
                        <br />
                        <br />
                        <h1>
                            <Icon
                                folder="/entities/"
                                id="query"
                                height={18}
                            />
                            Have you limited your Competitive Landscape to organizations classified within a folder?<br />
                        </h1>
                        <h1>
                            <Icon
                                id="check"
                                height={18}
                            />
                            If yes, note that the Classification filter is also restricted to those organizations<br />
                        </h1>
                        <br />
                        <h1>
                            <Icon
                                id="retract-arrow"
                                height={18}
                                style={{rotate: '90deg'}}
                            />
                            <a onClick={this.showFilterHelp}>More details</a>
                        </h1>
                    </>
                )
            ];

        return multiValued
            ? { title: 'This filter is "multi-valued"', content}
            : false;
    }


    /**
     * Render a Filter
     *
     * @param {Object} options
     */
    renderFilter(options) {         // eslint-disable-line max-lines-per-function
        const {
                filterKey,
                dynamicFilters
            } = options || {},
            {
                values, resource, customDateRange,
                restrictedFilters, isFiltersLoading,
            } = this.props;

        return (
            <FilterBlock
                key={filterKey}
                filterKey={filterKey}
                resource={resource}
                value={values[filterKey]}
                onChange={this.onChange}
                helpInfo={this.getHelpInfo(filterKey)}
                isFiltersLoading={isFiltersLoading}
                customDateRange={customDateRange}
                restrictedFilters={restrictedFilters}
                dynamicFilters={dynamicFilters}
            />
        );
    }


    /**
     * Render the filter help modal
     *
     * @returns JSX
     */
    renderFilterHelpModal() {   // eslint-disable-line max-lines-per-function
        const { showFilterHelp } = this.state;
        /* eslint-disable react/no-unescaped-entities  */
        /* eslint-disable max-len */
        // TODO: Clean this !!!!
        return (
            <Modal
                key="modal-filter-help"
                onCancel={this.hideFilterHelp}
                open={showFilterHelp}
                className="filter-help"
                footer={null}
                width={900}
            >

                <h1>
                    <Icon
                        id="folder"
                        height={18}
                    />
                    For example:
                </h1>
                - Let's assume folder A contains organization A (tag = Competitor), and folder B contains organization B (tag = Competitor).<br />
                - You then initiate a search that retrieves organizations A and B.<br />
                <br />
                <h1>
                    <Icon
                        id="check"
                        height={18}
                    />
                    With the option enabled:
                </h1>
                - The COMPETITIVE LANDSCAPE dashboard will exclusively display organization A.<br />
                - Only results concerning organization A and its subsidiaries will be displayed when you select the 'Competitor' tag in the CLASSIFICATION filter.<br />
                <br />
                <h1>
                    <Icon
                        id="close"
                        height={12}
                        width={18}
                    />
                    Without the option enabled:<br />
                </h1>
                - The COMPETITIVE LANDSCAPE dashboard will consider organizations A and B.<br />
                - Results for organizations A and B, as well as their respective subsidiaries,
                will be displayed in the document list when you select the 'Competitor' tag in the CLASSIFICATION filter.<br />
                <br />
                <h1>
                    <Icon
                        id="bookmarked"
                        width={18}
                        theme="outlined"
                    />
                    Try using this option to refine your search results and obtain more targeted information!<br />
                </h1>
            </Modal>
        );
    }

    /**
    * Render the Filters
    *
    * @return JSX
    */
    render() {
        const {
                resource, toHide, isFiltersLoading,
                dynamicFilters: newDynamicFilters,
                dynamicFiltersThresholdReached
            }                              = this.props,
            {
                filters,
                lastDynamicFilters
            }                              = this.state,
            { filters : defaultFilters }   = resource,
            dynamicFilters                 = !_.values(newDynamicFilters)?.length > 0
                && lastDynamicFilters
                ? lastDynamicFilters
                : newDynamicFilters,
            // FiltersKey based on defaultFilters when have no dynamicFilters
            filtersKeys                    = _.keys(dynamicFilters).length > 0
                ? _.keys(dynamicFilters)
                : defaultFilters,
            filtersKeysToShow              = filtersKeys.filter(key => !toHide.includes(key)),
            classNames                     = [
                'filters-container', {'is-loaded': !isFiltersLoading}
            ],
            className                      = makeStrClassName(classNames);

        if (!filters || !filtersKeysToShow) {
            return false;
        }

        return [(
            <div key="filters-container" className={className}>
                {filtersKeysToShow.map(
                    (filterKey) => this.renderFilter(
                        { filterKey, dynamicFilters }
                    )
                )}
                { dynamicFiltersThresholdReached && (
                    <div className="dynamic-filters-threshold-reached">
                        Because of a large result number (&gt;50000),
                        some display options will not be available for this search
                    </div>
                )}
            </div>
        ), this.renderFilterHelpModal()];
    }

}

Filters.propTypes = {
    restrictedFilters             : PropTypes.object,
    customDateRange               : PropTypes.any,
    dynamicFilters                : PropTypes.object,
    dynamicFiltersThresholdReached: PropTypes.bool,
    isFiltersLoading              : PropTypes.bool,
    toHide                        : PropTypes.array,
    learnKnowledge                : PropTypes.func,
    onChange                      : PropTypes.func,
    registerCallbacks             : PropTypes.func,
    values                        : PropTypes.object,
    resource                      : PropTypes.shape({
        filters: PropTypes.any
    }),
    colors: PropTypes.shape({
        Market: PropTypes.shape({
            active : PropTypes.string,
            default: PropTypes.string
        })
    }),
    Market: PropTypes.shape({
        active : PropTypes.string,
        default: PropTypes.string
    })
};

Filters.defaultProps = {
    colors: {
        Market: {
            active : 'var(--primary-color)',
            default: '#CCC',
        }
    },
    customDateRange               : null,
    dynamicFilters                : {},
    dynamicFiltersThresholdReached: false,
    isFiltersLoading              : false,
    values                        : {},
    toHide                        : [],
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Filters);
